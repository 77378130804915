
import { defineComponent, onMounted, ref } from "vue";
import RedacaoColumn from "@/util/table-columns/RedacaoColumn";
import useList from "@/modules/useList";
import Disciplina from "@/interfaces/Disciplina";
import TipoRedacao from "@/interfaces/TipoRedacao";
import BaseService from "@/services/admin/BaseService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Redacao from "@/interfaces/Redacao";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import store from "@/store";
import router from "@/router";
import DivisaoService from "@/services/auth/DivisaoService";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const filtro = ref<string>("");
    const filtroTipo = ref<string>("");
    const filtroDisciplina = ref<string>("");
    const tipoRedacoes = ref<TipoRedacao[]>([]);
    const disciplinas = ref<Disciplina[]>([]);
    const redacoes = ref<Redacao[]>([]);
    const submited = ref<boolean>(false);
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/redacoes/" + store.getters.divisaoId
        : "redacoes",
      RedacaoColumn
    );

    const pesquisarRedacao = (): void => {
      submited.value = true;
      if (filtro.value == "Tipo") {
        redacoes.value = rows.value.filter(
          (r: Redacao) => r.tipo_redacao.id == filtroTipo.value
        );
      } else if (filtro.value == "Disciplina") {
        redacoes.value = rows.value.filter(
          (r: Redacao) => r.disciplina.id == filtroDisciplina.value
        );
      }
    };

    const getTiposRedacao = (): void => {
      loading.value = true;
      BaseService.list("tipo-redacoes")
        .then((res) => {
          tipoRedacoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getDisciplinas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getDisciplinasSelect(store.getters.divisaoId)
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("disciplinas")
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const relatorio = (redacaoId: string): void => {
      router.push({
        name: "redacao-relatorio",
        params: { id: redacaoId },
      });
    };

    onMounted(() => {
      getDisciplinas();
      getTiposRedacao();
    });

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      filtro,
      filtroTipo,
      filtroDisciplina,
      disciplinas,
      tipoRedacoes,
      pesquisarRedacao,
      redacoes,
      store,
      relatorio,
      confirmDelete,
      submited,
    };
  },
});
